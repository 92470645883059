<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">MY TRAVELOGUE</span>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 md3 text-right>
            <v-btn
              color="#005f32"
              rounded
              outlined
              :to="'/Attendance/travelRequestForm'"
              ><span style="font-family: kumbhRegular">Apply Request</span></v-btn
            >
          </v-flex>
          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span class="itemText2"
                  >*Select the options to filter the results</span
                >
              </v-flex>
              <v-flex xl3 lg3 md3 pb-2 sm4 xs12 px-2 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Status</span
                >
                <v-select
                  :items="lists"
                  dense
                  v-model="status"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="rounded-xl"
                ></v-select>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm4 xs12 px-2 text-left>
                <v-flex xs12>
                  <span style="font-family: kumbhRegular; font-size: 14px"
                    >Month</span
                  >
                </v-flex>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      outlined
                      hide-details
                      dense
                  class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    no-title
                    type="month"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xl3 lg3 md3 pb-2 sm4 xs12 px-2 text-left>
                <span style="font-family: kumbhRegular; font-size: 14px"
                  >Mode of Travel</span
                >
                <v-select
                  :items="travelModes"
                  dense
                  v-model="travelMode"
                  item-text="name"
                  item-value="value"
                  outlined
                  class="rounded-xl"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start px-5 v-if="travelList.length > 0">
          <v-flex xs12 sm6 pa-4 v-for="(item, i) in travelList" :key="i">
            <v-card color="#ebe9e6">
              <v-layout wrap pa-4>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex xs12 sm12>
                      <span
                        style="
                          text-transform: uppercase;
                          font-family: kumbhBold;
                          font-size: 14px;
                        "
                        >{{ item.source }} - {{ item.destination }} &nbsp;<v-btn
                          rounded
                          elevation="0"
                          small
                          dark
                          :color="getStatusColor(item.status)"
                          >{{ item.status }}</v-btn
                        ></span
                      >
                    </v-flex>
                  </v-layout>
                  <v-layout wrap py-5 justify-start>
                    <v-flex xs12 text-left>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2"> Journey Starts on</span>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ formatDate(item.startingTime) }}
                            {{ convertUTCToLocal(item.startingTime) }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2"> Journey Ends on</span>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ formatDate(item.endingTime) }}
                            {{ convertUTCToLocal(item.endingTime) }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2"> Travel Mode</span>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ item.travelMode }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-layout wrap py-3>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2"> Description</span>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <span class="itemHeading2">
                            {{ item.description }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-divider></v-divider>
                      <v-flex xs12 pt-5 text-center>
                        <v-btn
                          color="#005f32"
                          dark
                          :to="
                            '/Attendance/myTravelDetails?id=' + item._id
                          "
                          ><span style="font-family: kumbhRegular; color: white;"
                            >View Details</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
      <script>
import axios from "axios";
export default {
  data: (vm) => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    status: "",
    lists: [
      {
        name: "All",
        value: "",
      },
      {
        name: "Pending",
        value: "pending",
      },
      {
        name: "Approved",
        value: "approved",
      },
      {
        name: "Rejected",
        value: "rejected",
      },
      {
        name: "Started",
        value: "started",
      },
      {
        name: "Completed",
        value: "completed",
      },
    ],
    travelMode: "",
    travelModes: [],
    travelList: [],
    data: [],
    name: null,
    assetId: null,
    date: new Date().toISOString().substr(0, 7),
    dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    menu1: false,
    menu2: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDateMonth(this.date);
    },
  },
  mounted() {
    this.getData();
    this.getTravelMode();
  },
  watch: {
    date() {
      this.currentPage = 1;
      this.dateFormatted = this.formatDateMonth(this.date);
      if (this.computedDateFormatted) {
        this.getData();
      }
    },
    status() {
      this.currentPage = 1;
      this.getData();
    },
    travelMode() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },

  methods: {
    getTravelMode() {
      axios({
        method: "GET",
        url: "/travelMode/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // console.log(response.data.data)
          if (response.data.status) {
            this.travelModes = [
              { name: "All", value: "" },
              ...response.data.data,
            ];
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((e) => {
          this.visible = false;
          this.errors.push(e);
          this.msg = "failed";
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/my/travelLog/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          page: this.currentPage,
          limit: 20,
          month: this.computedDateFormatted,
          status: this.status,
          travelMode: this.travelMode,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.travelList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStatusColor(status) {
        if (status == "pending") {
        return "warning";
      }
      if (status == "approved") {
        return "secondary";
      }
      if (status == "rejected") {
        return "red";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "started") {
        return "info";
      }
      if (status == "completed") {
        return "success";
      }
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatTime(item) {
      if (!item) return;
      if (item) item = item.slice(11, 16);
      var hours = Number(item.split(":")[0]);
      var minutes = Number(item.split(":")[1]);
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
    convertUTCToLocal(utcDateString) {
    // Create a new Date object from the UTC date string
    var utcDate = new Date(utcDateString);
  
    // Get the time zone offset in minutes
    // const timezoneOffset = utcDate.getTimezoneOffset();
    // console.log("timezoneOffset:", timezoneOffset);
  
    // Adjust the date for the time zone offset (convert from minutes to milliseconds)
    // utcDate.setMinutes(utcDate.getMinutes() - timezoneOffset);
  
    // utcDate = new Date(utcDate);

    // Get the year, month, day, hours, minutes, seconds, and milliseconds
    // const year = utcDate.getFullYear();
    // const month = utcDate.getMonth() + 1; // Months are zero-indexed (January is 0)
    // const day = utcDate.getDate();
  
    // Format the date string (YYYY-MM-DD)
    // const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  
    // Get hours, minutes, and format AM/PM indicator
    const hours = utcDate.getHours();
    const minutes = utcDate.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
  
    // Adjust hours for 12-hour format (convert from 24-hour to 12-hour)
    const adjustedHours = hours % 12 || 12;
  
    // Format the time string (HH:MM AM/PM)
    const formattedTime = `${adjustedHours}:${minutes} ${amPm}`;
  
    // Return the date and time as separate objects
    return formattedTime
    // return {
    //   date: formattedDate,
    //   time: formattedTime
    // };
  }
  },
};
</script>
    <style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>
      